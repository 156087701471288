export default [
    {
        path: "/",
        name: "index",
        component: () => import("@/views/Index"),
        meta: {
            layout: "base"
        }
    },

    {
        path: "/legal",
        name: "legal",
        component: () => import("@/views/Legal"),
        meta: {
            layout: "base"
        }
    },

    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard"),
        meta: {
            requiresAuth: true,
            layout: "base"
        }
    },


    //khatms
    {
        path: "/khatm/:uid",
        name: "khatm",
        props: true,
        component: () => import("@/views/modules/khatms/Khatm"),
        meta: {            
            layout: "base"
        }
    },

    {
        path: "/my-khatms",
        name: "myKhatms",
        props: true,
        component: () => import("@/views/modules/khatms/MyKhatms"),
        meta: {
            requiresAuth: true,
            layout: "base"
        }
    },


    //counter

    {
        path: "/counter/:uid",
        name: "counter",
        props: true,
        component: () => import("@/views/modules/counters/Counter"),
        meta: {
            layout: "base"
        }
    },

    {
        path: "/my-counters",
        name: "myCounters",
        props: true,
        component: () => import("@/views/modules/counters/MyCounters"),
        meta: {
            requiresAuth: true,
            layout: "base"
        }
    },

    {
        path: "/support",
        name: "support",
        component: () => import("@/views/Support"),
        meta: {
            layout: "base"
        }
    },

    
    {
        path: "/legal",
        name: "legal",
        component: () => import("@/views/Legal"),
        meta: {
            layout: "base"
        }
    },
    
    {
        path: "/update",
        name: "update",
        component: () => import("@/views/Update"),
        meta: {
            layout: "base"
        }
    },

 
    {
        path: "*",
        name: "404",
        component: () => import("@/views/Error"),
        meta: {
            layout: "base"
        }
    }
];
