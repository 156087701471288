import Vue from "vue";

import App from "./App.vue";

import router from "./router";

import store from "./store";

import moment from "moment";

import { BootstrapVue } from "bootstrap-vue";

import { VLazyImagePlugin } from "v-lazy-image";

import TextareaAutosize from "vue-textarea-autosize";

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import ToggleButton from 'vue-js-toggle-button'
import "vue2-animate/dist/vue2-animate.min.css";

Vue.use(BootstrapVue);

Vue.use(VLazyImagePlugin);
Vue.use(TextareaAutosize);

Vue.prototype.vWindow = window;

Vue.use(ToggleButton)

Vue.prototype.moment = moment;

//CSS
import "./assets/css/vendor/bootstrap/bootstrap.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/vendor/golden-ratio/gr.scss";
import "./assets/css/vendor/line-icons/scss/main.scss";
import "./assets/css/vendor/float-labels/float-labels.css";
import "./assets/css/theme.scss";
import "./assets/css/custom.scss";

//config
Vue.config.productionTip = false;

//filters
Vue.filter("capitalize", function(value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("jsonPretty", function(value) {
    return JSON.stringify(value, null, 2);
});


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
