<template>
  <div class="my-5 mx-auto pr-3 pl-3" style="max-width: 576px;">
    <div class="mx-auto py-3" v-if="add">
      <a
        href="https://rapidsoft.co.za"
        class="text-swap-dark text-decoration-none"
        v-if="add == 1"
      >
        <div class="row no-gutters">
          <div class="col-4 mx-auto">
            <img
              src="@/assets/img/ads/rapidsoft.png"
              class="img-fluid rounded"
            />
          </div>
          <div class="col-8 my-auto pl-2 pl-md-4" style="font-size: 14px">
            <p class="font-weight-bold mb-0 text-white">RapidSoft</p>
            <span class="text-white">
              RapidSoft provides custom software development, specialising in
              Point Of Sale and business systems.
            </span>

            <br />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-right-circle-fill text-success float-right"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
              />
            </svg>
          </div>
        </div>
      </a>

      <a
        href="https://propli.xyz"
        class="text-swap-dark text-decoration-none"
        v-if="add == 2"
      >
        <div class="row no-gutters">
          <div class="col-4 mx-auto">
            <img src="@/assets/img/ads/propli.png" class="img-fluid rounded" />
          </div>
          <div class="col-8 my-auto pl-2 pl-md-4" style="font-size: 14px">
            <p class="font-weight-bold mb-0 text-white">PropLi</p>
            <span class="text-white">
              Online Property Management tool for smart Landlords, as little as
              R3 per property.
            </span>
            <br />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-right-circle-fill text-success float-right"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
              />
            </svg>
          </div>
        </div>
      </a>

      <a
        href="https://niftybit.co.za"
        class="text-swap-dark text-decoration-none"
        v-if="add == 3"
      >
        <div class="row no-gutters">
          <div class="col-4 mx-auto">
            <img
              src="@/assets/img/ads/niftybit.png"
              class="img-fluid rounded"
            />
          </div>
          <div class="col-8 my-auto pl-2 pl-md-4" style="font-size: 14px">
            <p class="font-weight-bold mb-0 text-white">Nifty Bit</p>
            <span class="text-white">
              Bespoke websites and mobile app design and development.
            </span>
            <br />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-right-circle-fill text-success float-right"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
              />
            </svg>
          </div>
        </div>
      </a>

      <a
        href="https://masjids.xyz"
        class="text-swap-dark text-decoration-none"
        v-if="add == 4"
      >
        <div class="row no-gutters">
          <div class="col-4 mx-auto">
            <img src="@/assets/img/ads/must.png" class="img-fluid rounded" />
          </div>
          <div class="col-8 my-auto pl-2 pl-md-4" style="font-size: 14px">
            <p class="font-weight-bold mb-0 text-white">Masjid Times</p>
            <span class="text-white">
              Free Salaah Timetable Display Software For Masjids & Homes
              provides by rapidsoft.
            </span>
            <br />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-right-circle-fill text-success float-right"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
              />
            </svg>
          </div>
        </div>
      </a>

      <a
        href="https://halaal.recipes"
        class="text-swap-dark text-decoration-none"
        v-if="add == 5"
      >
        <div class="row no-gutters">
          <div class="col-4 mx-auto">
            <img
              src="@/assets/img/ads/halaal-recipes.png"
              class="img-fluid rounded"
            />
          </div>
          <div class="col-8 my-auto pl-2 pl-md-4" style="font-size: 14px">
            <p class="font-weight-bold mb-0 text-white">Halaal Recipes</p>
            <span class="text-white">
              Halaal Recipe Sharing Community Joining you with 1000's of members
              and recipes.
            </span>
            <br />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-right-circle-fill text-success float-right"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
              />
            </svg>
          </div>
        </div>
      </a>

      <a
        href="https://tajmie.site"
        class="text-swap-dark text-decoration-none"
        v-if="add == 6"
      >
        <div class="row no-gutters">
          <div class="col-4 mx-auto">
            <img src="@/assets/img/ads/tajmie.png" class="img-fluid rounded" />
          </div>
          <div class="col-8 my-auto pl-2 pl-md-4" style="font-size: 14px">
            <p class="font-weight-bold mb-0 text-white">Tajmie</p>
            <span class="text-white">
              A free smart receipting program to help organisations like
              Charities, Masjids & Madressahs.
            </span>
            <br />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-right-circle-fill text-success float-right"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
              />
            </svg>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      add: null,
      addMin: Math.ceil(7),
      addMax: Math.floor(1),
    };
  },
  mounted() {
    this.add = Math.floor(
      Math.random() * (this.addMax - this.addMin) + this.addMin
    );
  },
  updated() {
    this.add = Math.floor(
      Math.random() * (this.addMax - this.addMin) + this.addMin
    );
  },
  watch: {
    $route() {
      this.add = Math.floor(
        Math.random() * (this.addMax - this.addMin) + this.addMin
      );
    },
  },
};
</script>

<style>
</style>
