<template>
  <header class="py-4">
    <div class="row text-swap-dark header-menu-icons">
      <div class="col-auto">
        <i class="lni lni-menu pointer" v-on:click="showMenu"></i>
      </div>
      <div class="col text-right">
        <router-link class="text-swap-dark" :to="{ name: 'index' }" v-if="$route.name != 'index' ">
          <i class="lni lni-plus mr-3"></i>
        </router-link>
        <router-link class="text-swap-dark" :to="{ name: 'support' }">
          <i class="lni lni-help mr-3"></i>
        </router-link>

        <router-link class="text-swap-dark" v-if="!user" :to="{ name: 'login' }">
          <i class="lni lni-user mr-3"></i>
        </router-link>

        <router-link class="text-swap-dark" v-if="user" :to="{ name: 'dashboard' }">
         <small> {{ user.name.substring(0,17) }}</small>
        </router-link>


      </div>
    </div>

    <b-modal
      v-model="menuModal"
      title="Menu"
      headerClass="text-black"
      hide-footer
    >
      <div class="text-center mx-auto" style="max-width:200px;">
        <div class="mb-2 ">
          <a @click="goTo('index')" class="text-black pointer"
            >&bull; Create</a
          >
        </div>
        <div class="mb-2 ">
          <a @click="goTo('support')" class="text-black pointer"
            >&bull; Help/F.A.Q</a
          >
        </div>
        <div v-if="user" class="mb-2 ">
          <a @click="goTo('myKhatms')" class="text-black pointer"
            >&bull; My Khatms</a
          >
        </div>
        <div v-if="user" class="mb-2 ">
          <a @click="goTo('myCounters')" class="text-black pointer"
            >&bull; My Counters</a
          >
        </div>
        <div v-if="!user" class="mb-2 ">
          <a @click="goTo('login')" class="text-black pointer"
            >&bull; Login</a
          >
        </div>
        <div v-if="!user" class="mb-2 ">
          <a @click="goTo('register')" class="text-black pointer"
            >&bull; Create Account</a
          >
        </div>
         <div v-if="user" class="mb-2 ">
          <a @click="goTo('my-account')" class="text-black pointer"
            >&bull; My Account</a
          >
        </div>
        <div v-if="user" class="mb-2 ">
          <a @click="goTo('logoutAll')" class="text-black pointer"
            >&bull; Sign Out</a
          >
        </div>

      </div>
    </b-modal>
  </header>
</template>

<script>

export default {
  data() {
    return {
      menuModal: false,
    };
  },
  methods: {

    showMenu() {
      this.menuModal = true;
    },
    goTo(to){
      this.menuModal = false;
      this.$router.push({name:to})
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    $route() {
      this.menuModal = false;
    },
  },
};
</script>

<style scoped>
.header-menu-icons {
  font-size: 19px;
}
</style>
