const state = {
  layout: 'base-layout'
}

const mutations = {
  setLayout: (state, payload) => (state.layout = payload)
}

const getters = {
  layout: state => state.layout
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
