import api from './../../api/api'

export default {
  login: async credentials => {
    
    await api.get('sanctum/csrf-cookie')
    
    return api.post('api/login', {
      email: credentials.email,
      password: credentials.password,
      remember: credentials.remember
    })
    
  },

  register: async credentials => {
    
    await api.get('sanctum/csrf-cookie')
    
    return api.post('api/register', {
      email: credentials.email,
      password: credentials.password,
      name: credentials.name
    })
    
  },

  isLoggedIn: async () => {
    
    await api.get('sanctum/csrf-cookie')
    
    return api.get('api/is-logged-in')
    
  },

  forgotPassword: async email => {
    
    await api.get('sanctum/csrf-cookie')

    return api.post('api/forgot-password', { email: email })
  },


  resetPassword: async credentials => {
    
    await api.get('sanctum/csrf-cookie')

    return api.post('api/reset-password', {
      email: credentials.email,
      token: credentials.token,
      password: credentials.password
    })
  },

  logout: async () => {
    return api.post('api/logout', {
      token: localStorage.getItem('token')
    })
  },

  logoutAll: async () => {
    return api.post('api/logout-all', {
      token: localStorage.getItem('token')
    })
  },

  updateAccount: async user => {
    return api.put('api/my-account', user)
  }
}
