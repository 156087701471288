import authRepo from './../../api/repos/authRepo'

const state = {
  user: null, 
  token: null,
}

const mutations = {
  setAuth: state => {
    state.user = JSON.parse(localStorage.getItem('user'))     
    state.token = localStorage.getItem('token')

  },

  clearAuth: state => {
    state.user = null
    state.token = null   
  },

}

const getters = {
  user: state => state.user,
}

const actions = {
  doCheckAuth: async ({ commit }, credentials) => {
    let { data } = await authRepo.login(credentials)

    setToLocalStorage(data)
    commit('setAuth')
  },

  refreshAuth: async ({ commit }, data) => {

    setToLocalStorage(data)
    commit('setAuth')
  },

 
  doLogout: async ({ commit }) => {
    try {
      await authRepo.logout()
    } finally {
      clearFromLocalStorage()
      commit('clearAuth')
    }
  },

  doLogoutAll: async ({ commit }) => {
    try {
      await authRepo.logoutAll()
    } finally {
      clearFromLocalStorage()
      commit('clearAuth')
    }
  },

  doUpdateAccount: async ({ commit }, user) => {
    let { data } = await authRepo.updateAccount(user)
    setToLocalStorage(data)
    commit('setAuth')
  }
}

const setToLocalStorage = data => {

  if (data.token) localStorage.setItem('token', data.token)

  localStorage.setItem('user', JSON.stringify(data.user))  

}

const clearFromLocalStorage = () => {
  localStorage.removeItem('user') 
  localStorage.removeItem('token') 
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
