<template>
  <div>

    <div class="container">
        <base-header></base-header>
        <router-view></router-view>
        <base-footer></base-footer>
    </div>

    <add-block></add-block>

  </div>
</template>

<script>
import baseHeader from '../layouts/partials/BaseHeader'
import baseFooter from '../layouts/partials/BaseFooter'
import AddBlock from './partials/AddBlock.vue'

export default{
  components: {
    baseHeader,
    baseFooter,
    AddBlock
  }
}
</script>
