import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import menu from './modules/menu'
import Vuelidate from 'vuelidate'


Vue.use(Vuex)
Vue.use(Vuelidate)

// const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({

  // strict: debug,

  modules: {
    app: app,
    menu: menu,
    user: user
  }

})
