import Vue from 'vue'
import Router from 'vue-router'
import store from './../store'
import system from './modules/system'
import auth from './modules/auth'
import authRepo from "./../api/repos/authRepo";

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    ...system,
    ...auth,
  ]
})


/// ////////////////////
// Before Each Route //
/// ////////////////////
router.beforeEach(async (to, from, next) => {

  // load view layout
  store.commit('app/setLayout', to.meta.layout + '-layout')

  checkAuth();



  // Auth Required Pages
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        name: 'login'
      })
    } else {
      next()
    }

    // guest only pages
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('token') == null) {
      next()
    } else {
      next({
        name: 'home'
      })
    }
    // pages for both guest and authorized users
  } else {
    next()
  }
})

let checkAuth = async() => {
    try{
      await authRepo.isLoggedIn()

       // check if vuex has auth else fetch from local (in case of page refresh)
        if (localStorage.getItem('token') != null && store.getters['user.token'] == null) {
            store.commit('user/setAuth')
        }

        //server is signed and vue store so add to local storage
        else if (localStorage.getItem('token') == null && store.getters['user.token'] != null) {
            store.commit('user/setAuth')
        }

        //server is signed in but frontend is not so so clear
        else if (localStorage.getItem('token') == null && store.getters['user.token'] == null) {
            store.commit('user/clearAuth')
        }


    }catch(e){
      if(e){
       store.commit('user/clearAuth')
       localStorage.removeItem('user')
       localStorage.removeItem('token')
      }
    }
  }

export default router
