<template>
  <div class="h-100">
   
        <component v-bind:is="layout"/>
    
  </div>
</template>

<script>
import BaseLayout from './layouts/BaseLayout'

export default {
  computed: {
    layout () {
      return this.$store.getters['app/layout']
    }
  },
  components: {
    'base-layout': BaseLayout,
  },
 
}
</script>
