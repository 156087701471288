const state = {
  menuState: 'open'
}

const mutations = {
  setMenuState: (state, payload) => (state.menuState = payload)
}

const getters = {
  menuState: state => state.menuState
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
