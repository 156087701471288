export default [  
  {
    path: '/v-login',
    name: 'login',
    component: () => import('@/views/modules/auth/Login'),
    meta: {
      layout: 'base',
      
    }    
  },
  {
    path: '/v-register',
    name: 'register',
    component: () => import('@/views/modules/auth/Register'),
    meta: {
      layout: 'base'      
    }    
  },
  {
    path: '/v-forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/modules/auth/ForgotPassword'),
    meta: {
      layout: 'base'
    }
  },
  {
    path: '/v-reset-password',
    name: 'reset-password',
    component: () => import('@/views/modules/auth/ResetPassword'),
    meta: {
      layout: 'base'
    }
  },
  {
    path: '/v-logout',
    name: 'logout',
    component: () => import('@/views/modules/auth/Logout'),
    meta: {
      requiresAuth: true,
      layout: 'base'
    }
  },
  {
    path: '/v-logout-all',
    name: 'logoutAll',
    component: () => import('@/views/modules/auth/LogoutAll'),
    meta: {
      requiresAuth: true,
      layout: 'base'
    }
  },
  {
    path: '/my-account',
    props: true,
    name: 'my-account',
    component: () => import('@/views/modules/auth/my-account'),
    meta: {
      requiresAuth: true,
      layout: 'base',     
    }
  },
  

]