import axios from 'axios'


const api = axios.create({
  baseURL: process.env.VUE_APP_API_PATH + '/',
  timeout: 30000,
  withCredentials: true,
})

// Intercept Request
// api.interceptors.request.use(
//   config => {
//     config.headers['cid'] = getCurrentCompanyId()
//     return config
//   },
//   error => {
//     Promise.reject(error)
//   }
// )

// Intercept Response
api.interceptors.response.use(
  response => {
   
    if (parseFloat(response.headers['app-version']) > parseFloat(process.env.VUE_APP_VERSION)) {
      location.reload(true);
    }
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = '/v-login'
    }
    return Promise.reject(error)
  }
)

//const getCurrentCompanyId = () => localStorage.getItem('current_company_id')

export default api
